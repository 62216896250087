import React from 'react';
import { ajaxHoc } from '@/commons/ajax';
import { APP_NAME } from '@/config';
import { conventionalRoutes } from '@/pages/page-configs';
import { toLogin } from '@/commons';
import { withTranslation } from 'react-i18next';
import {
  modalFunctionHoc,
  modalHoc,
  drawerFunctionHoc,
  drawerHoc,
  isLoggedIn,
  ConfigHocParams,
  PickBoolConditionProps,
  InjectedProps,
  createConfigHoc,
} from '@rc-lib/pc';

// 弹框高阶组件默认属性
const defaultModalProps = {
  maskClosable: true,
  width: 600,
};

// 抽屉高级组件默认属性
const defaultDrawerProps = {
  maskClosable: true,
};

// 自定义_test高阶组件注入到组件内部的属性
type _TestProps<T extends ConfigAllHocParams> = PickBoolConditionProps<
  T,
  '_test',
  {
    _testName(): void;
  }
>;

// 扩展config参数
export interface ConfigAllHocParams extends ConfigHocParams {
  _test?: boolean;
}

// 扩展注入props参数
export type ConfigExtendProps<T extends ConfigAllHocParams> = _TestProps<T>;

const defaultConfig = createConfigHoc(
  [
    // 必须放到顶部
    { name: 'modalFunction', hoc: modalFunctionHoc, defaultOptions: defaultModalProps },
    { name: 'modal', hoc: modalHoc, defaultOptions: defaultModalProps },
    { name: 'drawerFunction', hoc: drawerFunctionHoc, defaultOptions: defaultDrawerProps },
    { name: 'drawer', hoc: drawerHoc, defaultOptions: defaultDrawerProps },

    { name: 'auth', hoc: authHoc, defaultEnable: true, defaultOptions: undefined, covertTrue: false },
    { name: 'ajax', hoc: ajaxHoc, defaultOptions: { propName: 'ajax' }, defaultEnable: true },
    { name: 't', hoc: () => withTranslation(), defaultEnable: false },
    { name: 'title', hoc: titleHoc, defaultEnable: true },
    { name: 'memo', hoc: memoHoc, defaultEnable: true, defaultOptions: true },
  ],
  { conventionalRoutes },
);

/**
 * 给组件添加memo高阶组件，优化性能
 */
function memoHoc(enable: boolean) {
  return (Component: React.ComponentType) => (enable ? React.memo(Component) : Component);
}

/**
 * 设置浏览器标题
 */
function titleHoc(title: string) {
  return function (Component: React.ComponentType<any>): React.ComponentType<any> {
    return function WithTitle(props: any) {
      // console.log(props.t); // 如果title需要国际化
      document.title = title ? `${title} - ${APP_NAME}` : APP_NAME;
      return React.createElement(Component, props);
    };
  };
}

/**
 * 登录拦截
 */
function authHoc(auth: boolean, config: ConfigAllHocParams) {
  return (Component: React.ComponentType<any>) =>
    function WithAuth(props: any) {
      if (!!config.title && auth && !isLoggedIn()) return toLogin();
      return React.createElement(Component, props);
    };
}

export type ConfigProps<ConfigParams extends ConfigAllHocParams> = InjectedProps<ConfigParams, ConfigAllHocParams>;

const config = <P extends ConfigAllHocParams = {}>(params: P & ConfigAllHocParams) => {
  return defaultConfig<ConfigExtendProps<P>, P>(params);
};

export default config;
