import React from 'react';
import { ConfigProvider } from 'antd';
import { ComponentProvider } from '@rc-lib/pc';
import { getLocale } from '../util';

const { antLocale, rcLocale } = getLocale();

interface Props {
  children: React.ReactNode;
}

export default function LocaleProvider(props: Props) {
  const { children } = props;

  return (
    <ConfigProvider locale={antLocale}>
      <ComponentProvider locale={rcLocale}>{children}</ComponentProvider>
    </ConfigProvider>
  );
}
