
        import { t } from '@/locale';
        import React from 'react';

const C0 = React.lazy(() => import('@/pages/example/css-specificity/index.jsx'));
const C1 = React.lazy(() => import('@/pages/example/demo/index.jsx'));
const C2 = React.lazy(() => import('@/pages/example/enum/index.jsx'));
const C3 = React.lazy(() => import('@/pages/example/locale/index.jsx'));
const C4 = React.lazy(() => import('@/pages/example/swagger-proxy/index.jsx'));
const C5 = React.lazy(() => import('@/pages/example/ts-demo/index.tsx'));
const C6 = React.lazy(() => import('@/pages/home/index.jsx'));
const C7 = React.lazy(() => import('@/pages/index.jsx'));
const C8 = React.lazy(() => import('@/pages/login/index-portal.jsx'));
const C9 = React.lazy(() => import('@/pages/login/index.jsx'));
const C10 = React.lazy(() => import('@/pages/preview/index.jsx'));
const C11 = React.lazy(() => import('@/pages/scene/debugModal/index.jsx'));
const C12 = React.lazy(() => import('@/pages/scene/detailModal/index.jsx'));
const C13 = React.lazy(() => import('@/pages/scene/editModal/index.jsx'));
const C14 = React.lazy(() => import('@/pages/scene/emailModal/index.jsx'));
const C15 = React.lazy(() => import('@/pages/scene/index.jsx'));
const C16 = React.lazy(() => import('@/pages/scene/sendEmailModal/index.jsx'));
const C17 = React.lazy(() => import('@/pages/system/users/index.jsx'));

export const conventionalRoutes =  [
    {
        "path": "/example/css-specificity",
        "exact": true,
        "Component": C0,
        "absComponent": "/home/app/.jenkins/workspace/far-idss-web/src/pages/example/css-specificity/index.jsx"
    },
    {
        "path": "/example/demo",
        "exact": true,
        "Component": C1,
        "absComponent": "/home/app/.jenkins/workspace/far-idss-web/src/pages/example/demo/index.jsx"
    },
    {
        "path": "/example/enum",
        "exact": true,
        "Component": C2,
        "absComponent": "/home/app/.jenkins/workspace/far-idss-web/src/pages/example/enum/index.jsx"
    },
    {
        "path": "/example/locale",
        "exact": true,
        "Component": C3,
        "absComponent": "/home/app/.jenkins/workspace/far-idss-web/src/pages/example/locale/index.jsx"
    },
    {
        "path": "/example/swagger-proxy",
        "exact": true,
        "Component": C4,
        "absComponent": "/home/app/.jenkins/workspace/far-idss-web/src/pages/example/swagger-proxy/index.jsx"
    },
    {
        "path": "/example/ts-demo",
        "exact": true,
        "Component": C5,
        "absComponent": "/home/app/.jenkins/workspace/far-idss-web/src/pages/example/ts-demo/index.tsx"
    },
    {
        "path": "/home",
        "exact": true,
        "Component": C6,
        "absComponent": "/home/app/.jenkins/workspace/far-idss-web/src/pages/home/index.jsx"
    },
    {
        "path": "/",
        "exact": true,
        "Component": C7,
        "absComponent": "/home/app/.jenkins/workspace/far-idss-web/src/pages/index.jsx"
    },
    {
        "path": "/login/index-portal",
        "exact": true,
        "Component": C8,
        "absComponent": "/home/app/.jenkins/workspace/far-idss-web/src/pages/login/index-portal.jsx"
    },
    {
        "path": "/login",
        "exact": true,
        "Component": C9,
        "absComponent": "/home/app/.jenkins/workspace/far-idss-web/src/pages/login/index.jsx"
    },
    {
        "path": "/preview",
        "exact": true,
        "Component": C10,
        "absComponent": "/home/app/.jenkins/workspace/far-idss-web/src/pages/preview/index.jsx"
    },
    {
        "path": "/scene/debugModal",
        "exact": true,
        "Component": C11,
        "absComponent": "/home/app/.jenkins/workspace/far-idss-web/src/pages/scene/debugModal/index.jsx"
    },
    {
        "path": "/scene/detailModal",
        "exact": true,
        "Component": C12,
        "absComponent": "/home/app/.jenkins/workspace/far-idss-web/src/pages/scene/detailModal/index.jsx"
    },
    {
        "path": "/scene/editModal",
        "exact": true,
        "Component": C13,
        "absComponent": "/home/app/.jenkins/workspace/far-idss-web/src/pages/scene/editModal/index.jsx"
    },
    {
        "path": "/scene/emailModal",
        "exact": true,
        "Component": C14,
        "absComponent": "/home/app/.jenkins/workspace/far-idss-web/src/pages/scene/emailModal/index.jsx"
    },
    {
        "path": "/scene",
        "exact": true,
        "Component": C15,
        "absComponent": "/home/app/.jenkins/workspace/far-idss-web/src/pages/scene/index.jsx"
    },
    {
        "path": "/scene/sendEmailModal",
        "exact": true,
        "Component": C16,
        "absComponent": "/home/app/.jenkins/workspace/far-idss-web/src/pages/scene/sendEmailModal/index.jsx"
    },
    {
        "path": "/system/users",
        "exact": true,
        "Component": C17,
        "absComponent": "/home/app/.jenkins/workspace/far-idss-web/src/pages/system/users/index.jsx"
    }
]

        export default [{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/example/css-specificity/index.jsx',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        
},{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/example/demo/index.jsx',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        
},{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/example/demo/main-action.js',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        },{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/example/enum/index.jsx',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        
  auth: false,
},{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/example/locale/index.jsx',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        
  t: true,
},{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/example/swagger-proxy/index.jsx',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        
},{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/example/ts-demo/components/detail-modal/index.tsx',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        
  modalFunction: true,
},{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/example/ts-demo/index.tsx',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        
},{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/home/index.jsx',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        
},{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/index.jsx',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        },{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/login/index-portal.jsx',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        
  layout: false,
  auth: false,
},{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/login/index.jsx',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        
  layout: false,
  auth: false,
},{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/page-configs.js',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        },{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/preview/index.jsx',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        
  layout: false,
},{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/preview/style.js',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        },{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/scene/debugModal/index.jsx',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        
  modalFunction: true,
},{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/scene/detailModal/index.jsx',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        
  modalFunction: true,
},{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/scene/editModal/index.jsx',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        
  modalFunction: true,
},{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/scene/emailModal/index.jsx',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        
  modalFunction: true,
},{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/scene/index.jsx',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        
  title: t('场景管理'),
},{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/scene/sendEmailModal/index.jsx',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        
  modalFunction: true,
},{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/system/users/detailModal.jsx',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        
  modalFunction: true,
},{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/system/users/editModal.jsx',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        
  modalFunction: true,
},{
            filePath: '/home/app/.jenkins/workspace/far-idss-web/src/pages/system/users/index.jsx',
            
            pagesPath: '/home/app/.jenkins/workspace/far-idss-web/src/pages',
        
}]
    