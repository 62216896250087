import { Button, Tooltip, Space, Dropdown ,Breadcrumb} from 'antd';
import { LogoutOutlined, LoginOutlined, UserOutlined, DownOutlined } from '@ant-design/icons';
import { useFunction, getLoginUser, confirm, FullScreen, setLoginUser } from '@rc-lib/pc';
import config from '@/commons/config-hoc';

import s from './style.module.less';
import { toLogin,getCurrentPageConfig } from '@/commons';

export default config({
  t: true,
})(function Header(props) {
  const { t } = props;
  console.log(props,'props')
  const loginUser = getLoginUser();
  const { title } = getCurrentPageConfig();
  const { pathname } = window.location
  const handleLogout = useFunction(async () => {
    await confirm(t('您确定要退出登录？'));
    // TODO 退出登录
    setLoginUser(null);
    toLogin();
  });
  console.log(getCurrentPageConfig(),'getCurrentPageConfig')
  return (
    <Space className={s.header}>
      <FullScreen />

      <div className={s.user}>
        {loginUser ? (
          <Dropdown menu={{ items: [{ key: '1', label: t('个人中心') }] }}>
            <Space style={{ cursor: 'pointer' }}>
              <UserOutlined />
              {loginUser.name}
              <DownOutlined />
            </Space>
          </Dropdown>
        ) : null}
      </div>

      {loginUser ? (
        <Tooltip title={t('退出登录')}>
          <div
            className={s.action}
            onClick={handleLogout}
          >
            <LogoutOutlined />
          </div>
        </Tooltip>
      ) : (
        <Button
          icon={<LoginOutlined />}
          type={'link'}
          onClick={() => toLogin()}
        >
          {t('请登录')}
        </Button>
      )}
    </Space>
  );
});
