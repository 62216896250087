import { ConfigAllHocParams } from '@/commons/config-hoc';
import pageConfig, { conventionalRoutes } from '@/pages/page-configs';
import { ComponentType, LazyExoticComponent, lazy } from 'react';

interface PageItem extends ConfigAllHocParams {
  filePath: string;
  pagesPath: string;
  component: any;
}

type routeItem = {
  Component: LazyExoticComponent<ComponentType<any>>;
  absComponent: string;
  exact: boolean;
  path: string;
  keepAlive?: boolean;
};

const pages = pageConfig as PageItem[];
const routes = conventionalRoutes as routeItem[];

// 配置化路由
const configRoutes = pages
  .filter((item) => !!item.path)
  .map((item) => {
    const { path, component, filePath } = item;

    return {
      path: path as string,
      exact: true,
      Component: lazy(component),
      absComponent: filePath,
    };
  });

// 约定路由
const convRoutes = routes.map((item) => {
  const config = pages.find((it) => it.filePath === item.absComponent);
  return {
    ...item,
    keepAlive: config?.keepAlive,
  };
});

// 基于path去重
export default [...configRoutes, ...convRoutes].reduce((prev, curr) => {
  if (!prev.some((item) => item.path === curr.path)) prev.push(curr);
  return prev;
}, [] as routeItem[]);
