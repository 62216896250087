const { REACT_APP_IS_SUB, REACT_APP_RUN_ENV, REACT_APP_AJAX_PREFIX, REACT_APP_AJAX_TIMEOUT, REACT_APP_BASE_NAME } =
  process.env;
// 判断是否运行在iframe中
export const isInIframe = window.self !== window.top;
// 应用名称
export const APP_NAME = '智能决策中台';
// node环境
export const NODE_ENV = process.env.NODE_ENV;
// 静态文件前缀
export const PUBLIC_URL = process.env.PUBLIC_URL || '';
// 实际运行环境，测试、预发布等环境时 NODE_ENV 也为 production，无法区分
export const RUN_ENV = REACT_APP_RUN_ENV || NODE_ENV;
// 是否是开发环境
export const IS_DEV = RUN_ENV === 'development';
// 是否是生产环境
export const IS_PROD = RUN_ENV === 'production';
// 是否是测试环境
export const IS_TEST = RUN_ENV === 'test';
// 是否是预览
export const IS_PREVIEW = RUN_ENV === 'preview';
// 是否作为微前端子项目，或者嵌入在iframe中
export const IS_SUB = REACT_APP_IS_SUB || isInIframe;
// 是否显示切换代理组件，嵌入其他系统、非开发测试环境不显示
export const SHOW_PROXY = !IS_SUB && (NODE_ENV === 'development' || RUN_ENV === 'test');
// ajax 请求前缀 开发环境 或者 测试环境使用 localStorage中存储的前缀
export const AJAX_PREFIX =
  REACT_APP_AJAX_PREFIX || (SHOW_PROXY && window.localStorage.getItem('AJAX_PREFIX')) || '/api';
// ajax 默认超时时间
export const AJAX_TIMEOUT = REACT_APP_AJAX_TIMEOUT || 1000 * 60 * 60;
// 页面路由前缀，开发模式下，如果配置了 PUBLIC_URL， 会强制等于 PUBLIC_URL
export const BASE_NAME = IS_DEV && PUBLIC_URL ? PUBLIC_URL : REACT_APP_BASE_NAME || '';
// 页面保持总开关
export const KEEP_PAGE_ALIVE = false;
// 是否忽略权限
export const IGNORE_PERMISSION = false;
