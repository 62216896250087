import { Layout, LayoutProps } from '@rc-lib/pc';
import menus from '@/menus';
import Logo from '../logo';
import Header from './Header';

interface Props extends LayoutProps {}

export default (props: Props) => {
  const { children, ...others } = props;
  return (
    <Layout
      logo={(c) => <Logo collapsed={c} />}
      header={<Header />}
      menus={menus}
      {...others}
    >
      {children}
    </Layout>
  );
};
