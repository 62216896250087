import { APP_NAME } from '@/config';
import c from 'classnames';
import s from './style.module.less';

interface Props {
  collapsed?: boolean;
  className?: string;
}

export default function Logo(props: Props) {
  const { className, collapsed, ...others } = props;

  return (
    <h1
      className={c(s.logo, className)}
      {...others}
    >
      {collapsed ? APP_NAME.substring(0, 2) : APP_NAME}
    </h1>
  );
}
