import { Ajax, createAjaxHoc, getToken } from '@rc-lib/pc';
import { AJAX_PREFIX, AJAX_TIMEOUT } from '@/config';
import { handleError, handleSuccess } from '@/commons';
import { AxiosRequestConfig } from 'axios';

// 创建Ajax实例，设置默认值
const ajax = new Ajax({
  baseURL: AJAX_PREFIX,
  timeout: Number(AJAX_TIMEOUT),
  onError: handleError,
  onSuccess: handleSuccess as any,
  noEmpty: false,
  // 强制禁用缓存
  headers: {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
  },
  // 跨域携带cookie，对应后端 Access-Control-Allow-Origin不可以为 '*'，需要指定为具体域名
  // withCredentials: true,
});

interface CFG<T> extends AxiosRequestConfig<T> {
  permission?: string;
}
// 请求拦截
ajax.instance.interceptors.request.use(
  (cfg: CFG<any>) => {
    if (!cfg.headers) cfg.headers = {};
    // 这里每次请求都会动态获取，放到创建实例中，只加载一次，有时候会出问题。
    cfg.headers['auth-token'] = getToken();
    if (cfg.permission) cfg.headers['Func_Code'] = cfg.permission;
    return cfg;
  },
  (error) => Promise.reject(error),
);

// 响应拦截
ajax.instance.interceptors.response.use(
  (res) => {
    if (typeof res.data !== 'object') return res;

    if (Array.isArray(res.data)) return res;

    if (!res.data) return res;

    // if ('code' in res.data && res.data.code !== 200 ||'code' in res.data && res.data.code !== 0) return Promise.reject(res.data);

    // res.data = res.data.data;

    return res;
  },
  (error) => Promise.reject(error),
);

export default ajax;
export const ajaxHoc = createAjaxHoc(ajax);
export const get = ajax.get;
export const post = ajax.post;
export const put = ajax.put;
export const del = ajax.del;
export const patch = ajax.patch;
export const download = ajax.download;
export const upload = ajax.upload;
